import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../../components/layout"
import Grid from "../../components/grid"
import SEO from "../../components/seo"
import Modal from "../../components/modal"
import Dropdown from "../../components/dropdown"
import classNames from "classnames"
import ModalInner from "../../components/modalInner"

import styles from "../../styles/pages/berenson-capital/portfolio.module.scss"
import pageStyles from "../../styles/pages/page-styles.module.scss"

class Company extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      modal: false,
    }
  }

  modalToggle = () => {
    this.setState(state => ({
      modal: !state.modal,
    }))
  }

  render() {
    let rightSections = []
    if (this.props.category?.name) {
      rightSections.push({ title: "Status", items: [this.props.category.name] })
    }
    if (this.props.sector) {
      rightSections.push({ title: "Sector", items: [this.props.sector] })
    }
    if (this.props.websiteLink) {
      rightSections.push({
        title: "Website",
        link: this.props.websiteLink,
        text: this.props.websiteLink,
      })
    }
    console.log(rightSections)
    return (
      <>
        <button
          className={styles.companyCard}
          aria-label={this.props.name}
          onClick={this.modalToggle}
        >
          <Img
            alt={this.props.logo.description}
            className={styles.image}
            fluid={this.props.logo.fluid}
            imgStyle={{ objectFit: "contain" }}
          />
        </button>
        <Modal
          portfolio
          className={styles.modal}
          display={this.state.modal}
          close={this.modalToggle}
        >
          <ModalInner
            header={
              <div className={styles.logoWrapper}>
                <Img
                  alt={this.props.logo.description}
                  className={styles.image}
                  fluid={this.props.logo.fluid}
                />
              </div>
            }
            footer={null}
            aboutBlurb={this.props.description.description}
            rightSections={rightSections}
          />
        </Modal>
      </>
    )
  }
}

class PortfolioPage extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      filter: null,
    }
  }

  filter = option => {
    this.setState({
      filter: option,
    })
  }

  render() {
    const pageData = this.props.data.contentfulPortfolioPage
    let categories = this.props.data.allContentfulPortfolioCategory.edges
    let options = categories.map(category => [
      category.node.name,
      () => this.filter(category.node.id),
    ])
    options.sort((a, b) => {
      return b[0] > a[0] ? -1 : 1
    })
    let companies = pageData.portfolioCompanies.portfolioCompanyList
    let displayedCompanies = companies.filter(elem => {
      return !this.state.filter || this.state.filter === elem.category.id
    })
    companies = displayedCompanies.map(elem => (
      <Company {...elem} key={elem.id} />
    ))

    return (
      <Layout
        location={this.props.location.pathname}
        disclaimer={pageData.disclaimer}
      >
        <SEO title="Portfolio" />
        {/*<BackgroundLines yPosition={600} />*/}
        <div className={pageStyles.pageContentContainer}>
          <div className={styles.pageHeader}>
            <h2 className={pageStyles.pageTitle}>Portfolio</h2>
            <div
              className={classNames(styles.dropdown, {
                [styles.hide]: !pageData.displayFilters.displayFilter,
              })}
            >
              <Dropdown
                filter
                title="Filter"
                options={options}
                defaultFunc={() => this.filter(null)}
              />
            </div>
          </div>
          {pageData.portfolioCompanies.title && (
            <h3>{pageData.portfolioCompanies.title}</h3>
          )}
          <div className={styles.gridContainer}>
            <div className={styles.content}>
              <Grid maxColumns={3}>{companies}</Grid>
            </div>
          </div>
          <h3 className={styles.additionalTitle}>
            {pageData.additionalPortfolioCompanies.title}
          </h3>
          <div className={styles.gridContainer}>
            <div className={styles.content}>
              <Grid maxColumns={3}>
                {pageData.additionalPortfolioCompanies.portfolioCompanyList.map(
                  elem => (
                    <Company {...elem} key={elem.id} />
                  )
                )}
              </Grid>
            </div>
          </div>
        </div>
      </Layout>
    )
  }
}

export default PortfolioPage

export const portfolioCompanyQuery = graphql`
  query {
    allContentfulPortfolioCategory {
      edges {
        node {
          name
          id
        }
      }
    }
    contentfulPortfolioPage {
      disclaimer
      portfolioCompanies {
        title
        portfolioCompanyList {
          name
          description {
            description
          }
          id
          websiteLink
          category {
            name
            id
          }
          sector
          logo {
            description
            fluid(maxWidth: 215, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
      displayFilters {
        displayFilter
      }
      additionalPortfolioCompanies {
        title
        portfolioCompanyList {
          name
          description {
            description
          }
          id
          websiteLink
          category {
            name
            id
          }
          sector
          logo {
            description
            fluid(maxWidth: 215, quality: 100) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
  }
`
