import React from "react"
import classNames from "classnames"

import styles from "../styles/components/dropdown.module.scss"
import DropdownArrow from "../images/dropdown-arrow.svg"

class Dropdown extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      open: false,
      active: null,
    }

    this.ref = React.createRef()
    this.ref2 = React.createRef()
  }

  handleClick = event => {
    if (
      event.target !== this.ref.current &&
      event.target !== this.ref2.current
    ) {
      this.setState({
        open: false,
      })
    }
  }

  componentDidMount() {
    window.addEventListener("click", this.handleClick)
  }

  componentWillUnmount() {
    window.removeEventListener("click", this.handleClick)
  }

  toggleOpen = () => {
    this.setState(state => {
      return {
        open: !state.open,
      }
    })
  }

  changeActive = active => {
    this.setState({
      active,
      open: false,
    })
  }

  render() {
    let { className, filter, title, options, defaultFunc } = this.props
    let { active } = this.state
    return (
      <div
        className={classNames(styles.container, className, {
          [styles.open]: this.state.open,
        })}
      >
        <button
          ref={this.ref}
          className={styles.mainBox}
          onClick={this.toggleOpen}
        >
          {active ? active : title}
          <div ref={this.ref2} className={styles.dropdownOverlayContainer} />
          <DropdownArrow />
        </button>
        <div className={styles.listContainer}>
          <ul>
            {filter && active && (
              <li>
                <button
                  onClick={() => {
                    defaultFunc()
                    this.changeActive(null)
                  }}
                >
                  All
                </button>
              </li>
            )}
            {options.map(option => {
              let label = option[0]
              let func = option[1]
              if (active !== label)
                return (
                  <li key={label}>
                    <button
                      onClick={() => {
                        func()
                        this.changeActive(label)
                      }}
                    >
                      {label}
                    </button>
                  </li>
                )
              return null
            })}
          </ul>
        </div>
      </div>
    )
  }
}

export default Dropdown
